<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="12" lg="10">
        <v-card class="pa-4">
          <v-card-actions>
            <v-card-title>Add Users</v-card-title>
            <v-spacer> </v-spacer
            ><v-btn
              tag="a"
              color="secondary"
              outlined
              dark
              href="/files/meetingme_org_users_template.csv"
              download
              >Download Template
              <v-icon color="secondary" right> mdi-download </v-icon>
            </v-btn>
          </v-card-actions>
          <v-divider class="mx-4 mb-6"></v-divider>

          <!-- <v-spacer></v-spacer> -->
          <!-- <v-btn icon @click="$emit('closeDialog')">
        <v-icon large color="red lighten-2">mdi-close-circle-outline</v-icon>
      </v-btn> -->

          <v-file-input
            v-model="user_room_csv"
            outlined
            dense
            label="Upload User CSV"
            @change="loadCSV"
          >
          </v-file-input>

          <v-divider></v-divider>
          <v-data-table class="ma-4" :headers="headers" :items="items">
            <template v-slot:item.username="props">
              <v-edit-dialog :return-value.sync="props.item.username">
                {{ props.item.username }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.username"
                    label="Edit"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.first_name="props">
              <v-edit-dialog :return-value.sync="props.item.first_name">
                {{ props.item.first_name }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.first_name"
                    label="Edit"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.last_name="props">
              <v-edit-dialog :return-value.sync="props.item.last_name">
                {{ props.item.last_name }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.last_name"
                    label="Edit"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.email="props">
              <v-edit-dialog :return-value.sync="props.item.email">
                {{ props.item.email }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.email"
                    label="Edit"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.phone="props">
              <v-edit-dialog :return-value.sync="props.item.phone">
                {{ props.item.phone }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.phone"
                    label="Edit"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.role="props">
              <!-- <v-edit-dialog :return-value.sync="props.item.role">
                {{ props.item.role }}
                <template v-slot:input> -->
              <v-select
                v-model="props.item.role"
                :items="[
                  {
                    text: 'Admin',
                    value: 'ADMIN',
                  },
                  {
                    text: 'Teacher',
                    value: 'TEACHER',
                  },
                  {
                    text: 'Student',
                    value: 'STUDENT',
                  },
                ]"
              ></v-select>
              <!-- </template>
              </v-edit-dialog> -->
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="pa-4"
              color="primary"
              @click="assignRoomUsers"
              :loading="loading"
            >
              Assign Users To Rooms
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  emits: ["close"],
  data() {
    return {
      user_room_csv: "",
      headers: [],
      items: [],
      loading: false,
    };
  },
  computed: {},
  methods: {
    loadCSV() {
      if (this.user_room_csv) {
        const file_reader = new FileReader();
        file_reader.readAsText(this.user_room_csv);
        file_reader.onload = (e) => {
          const csv_data = e.target.result;
          const csv_user_room_data = csv_data.trim().split("\n");
          this.headers = csv_user_room_data[0]
            .split(",")
            .map((e) => ({ text: e.trim(), value: e.trim() }));
          csv_user_room_data.shift();
          this.items = csv_user_room_data.map((row) => {
            const [
              username,
              first_name,
              last_name,
              email,
              country_code,
              phone,
              password,
              role,
            ] = row.split(",").map((e) => e.trim());
            return {
              username,
              first_name,
              last_name,
              email,
              country_code,
              phone,
              password,
              role: role.toUpperCase(),
            };
          });
        };
      } else {
        this.items = [];
        this.headers = [];
      }
    },

    assignRoomUsers() {
      this.loading = true;
      return this.$api
        .post("users/bulk-create/", {
          users: this.items,
        })
        .then((res) => {
          this.headers = [
            ...this.headers,
            { text: "Assigned", value: "user_exist_status" },
            { text: "Error", value: "room_error" },
          ];
          this.items = [];
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style>
</style>
